
.custom-tab-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 8px 56px;
  }
  
  .active-tab {
    color: #1D2026;
  }
  /* styles.css or styles.less */
  .ant-tabs-ink-bar {
    background-color: #ff6636 !important; /* Set the inkBarColor to orange */
  }
  
  .inactive-tab {
    color: gray;
    font-weight: 500;
  }
  
  .icon {
    width: 20px;
    margin-right: 8px;
  }
  