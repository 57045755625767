.custom-placeholder::placeholder {
  font-size: 16px;
  color: #8c94a3;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}
.custom-placeholder .ant-select-selector .ant-select-selection-placeholder {
  font-size: 16px;
  color: #8c94a3;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}
.custom-tab-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px 56px;
}
